<template>
  <div v-if="!processing" class="room">
    <h1>{{ $t('checkout.content.title') }}</h1>

    <p class="checkout-description">
      {{ checkOutText || $t('checkout.content.description') }}
    </p>

    <ProfileDetail />
    <LanguageSelect absolutePosition />

    <div v-if="checkOutByRoom" class="room-list">
      <RoomItem v-for="room in rooms"
                :key="room.id"
                :room="room"
                :loading="loading === room.id"
                @click="checkOutOfTheRoom"
      >
        <template v-slot:action>
          <ButtonInput type="button"
            @click="() => requestCheckOutFromTheRoom(room)"
            :loading="loading === room.id"
            :disabled="loading === room.id"
          >
            {{ $t('checkout.action.make_checkout') }}
          </ButtonInput>
        </template>
      </RoomItem>
    </div>
    <div v-else>
      <ButtonInput type="button"
        @click="() => logEvent('onCheckOutMakeCheckOutClicked') & requestCheckOutFromTheRoom(null)"
        :loading="loading"
        :disabled="loading"
      >
        {{ $t('checkout.action.make_checkout') }}
      </ButtonInput>
    </div>
  </div>
  <div v-else class="room">
     <h1>{{ $t('checkout.content.title_processing') }}</h1>

    <h2 v-if="process === 'waiting'" class="checkout-description">
      {{ $t('checkout.content.description_waiting') }}
    </h2>
    <h2 v-if="process === 'intaking'" class="checkout-description">
      {{ $t('checkout.content.description_intaking') }}
    </h2>
    <h2 v-if="process === 'processing'" class="checkout-description">
      {{ $t('checkout.content.description_processing') }}
    </h2>

    <p>
      {{ $t('checkout.content.cards_count', {
        checkOutCount: checkOutCount,
        cardsReturned: cardsReturned,
      }) }}
    </p>

    <ButtonInput type="button"
        @click="() => noMoreCardsToReturn()"
        :disabled="process !== 'intaking'"
      >
        {{ $t('checkout.action.no_more_cards') }}
      </ButtonInput>

    <ProfileDetail />
    <LanguageSelect absolutePosition />
  </div>
</template>

<script>
import _ from 'lodash';
import RoomItem from '@/components/RoomList/RoomItem';
import LanguageSelect from '@/components/LanguageSelect/index';
import ProfileDetail from '@/components/ProfileDetail';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import { mapGetters } from 'vuex';
import {
  GET_ROOMS,
  GET_RESERVATION_DETAIL,
} from '@/store/reservation';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import api from '@/api/handler';
import flashMessage from '@/helpers/flashMessage';
import { CHECKOUT_BY_RESERVATION_PMS } from '@/constants/pmsIntegration';
import errorHandler from '@/api/errorHandler';
import * as exceptionCode from '@/constants/exceptionsCode';
import confirmDialog from '@/helpers/confirmDialog';
import { checkOutHandler, createWsConnection } from '@/helpers/cardHelper';
import * as cisaHelper from '@/helpers/mobileKeys/cisaHelper';
import * as saltoEncoderHelper from '@/helpers/encoders/saltoEncoderHelper';
import * as AssaAbloyVingCardEncoderHelper from '@/helpers/encoders/assaAbloyVingCardEncoderHelper';
import * as AssaAbloyTesaCardEncoderHelper from '@/helpers/encoders/assaAbloyTesaCardEncoderHelper';
import * as onityEncoderHelper from '@/helpers/encoders/onityEncoderHelper';
import * as dormakabaEncoderHelper from '@/helpers/encoders/dormakabaEncoderHelper';
import { FALLBACK_LANGUAGE, ISO_CODE_TO_LANGUAGE } from '@/constants/trans';
import { logEvent } from '@/helpers/analytics';
import * as keySystemType from '@/constants/keySystemType';

export default {
  name: 'CheckOutRoom',
  async mounted() {
    if (!this.reservation) {
      await this.$router.push('/home');
    }
  },
  components: {
    RoomItem,
    LanguageSelect,
    ProfileDetail,
    ButtonInput,
  },
  data() {
    return {
      room: null,
      processing: false,
      process: 'waiting',
      wsClient: null,
      loading: false,
      checkOutCount: 0,
      cardsReturned: 0,
    };
  },
  computed: {
    ...mapGetters({
      rooms: GET_ROOMS,
      reservation: GET_RESERVATION_DETAIL,
      property: GET_PROPERTY_DETAIL,
    }),
    checkOutByRoom() {
      return CHECKOUT_BY_RESERVATION_PMS.indexOf(this.property.integration) < 0;
    },
    keyService() {
      return _.get(this.property, 'key_service');
    },
    checkOutText() {
      const fallback = _.get(this.property, `settings.checkOut.${ISO_CODE_TO_LANGUAGE[FALLBACK_LANGUAGE]}`);
      return _.get(this.property, `settings.checkOut.${ISO_CODE_TO_LANGUAGE[this.$i18n.locale] || this.$i18n.locale}`) || fallback;
    },
  },
  methods: {
    logEvent,
    requestCheckOutFromTheRoom(room) {
      this.room = room;
      const el = `<div class="checkout-modal-content">
          <p>
            <strong>${ this.$t('checkout.modal.checkout_confirm_first') }</strong>
          </p>
          <p>
            ${ this.$t('checkout.modal.checkout_confirm') }
          </p>
        </div>`;
      confirmDialog(this.$store, el, () => this.checkOutOfTheRoom(room), null);
    },
    checkOutOfTheRoom(room) {
      if (this.processing) {
        return;
      }

      this.processing = true;
      this.loading = true;

      if (!room) {
        room = this.rooms[0];
      }

      this.room = room;

      const controllerWebsocketServer = 'ws://' + _.get(this.property, 'settings.controller_websocket_server');
      const kioskSettings = _.get(this.property, 'settings');
      const encoderNo = _.get(kioskSettings, 'encoder_no');

      this.checkOutCount = this.calculateCardsToReturn(room);

      if (parseInt(process.env.VUE_APP_DEBUG, 10) === 1) {
        return this.checkOutAtPms(room);
      }

      if (this.keyService === keySystemType.TTLOCK) {
        this.processing = false;
        return this.checkOutAtPms(room);
      }

      try {
        createWsConnection(controllerWebsocketServer)
          .then((wsClient) => {
            this.wsClient = wsClient;
            this.checkOutManage(wsClient, room, encoderNo, kioskSettings, controllerWebsocketServer);
          })
          .catch(() => {
            this.processing = false;
            this.loading = false;
            flashMessage(this.$store, this.$t('checkout.alert.error.failed_checkout'), 'danger');
          });
      } catch (e) {
        this.processing = false;
        this.loading = false;
        flashMessage(this.$store, this.$t('checkout.alert.error.failed_checkout'), 'danger');
      }
    },
    checkOutManage(wsClient, room, encoderNo, kioskSettings, controllerWebsocketServer) {
      checkOutHandler(wsClient, room, encoderNo, controllerWebsocketServer, null, kioskSettings, this.setProcessStatus)
        .then(() => {
          console.log('Done ' + (this.cardsReturned + 1) + '/' + this.checkOutCount);
          if (this.cardsReturned < this.checkOutCount - 1) {
            this.cardsReturned += 1;
            return this.checkOutManage(wsClient, room, encoderNo, kioskSettings, controllerWebsocketServer);
          }
          wsClient.close();
          this.checkOutAtPms(room);
        })
        .catch((e) => {
          throw e;
        });
    },
    checkOutAtPms(room) {
      api(this, endpoints.ENDPOINT_CHECKOUT, methods.POST_ROOM_CHECKOUT)(this.reservation.id, room.id)
        .then(({metadata}) => {
          if (!metadata || (metadata && metadata.success)) {
            if (this.$route.name === 'checkout') {
              this.$router.push({
                name: 'checkout-success',
                params: {
                  id: room.id,
                },
              });
            }
          } else {
            this.loading = false;
            flashMessage(this.$store, this.$t('checkout.alert.error.unable_checkout'), 'danger');
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;

          let message = errorHandler(err.response);

          const data = _.get(err, 'response.data.responseBody.metadata.data');
          const code = _.get(data, 'code');

          if (!this.checkOutByRoom) {
            if ([
              exceptionCode.CHECKOUT_FAILED_UNPAID_BILLS,
              exceptionCode.ERROR_CHECKOUT,
            ].indexOf(code) >= 0) {
              this.$router.push({
                name: 'checkout-error',
                params: {
                  id: room.id,
                },
              });
              return;
            }
          }

          flashMessage(this.$store, this.$t(message) || this.$t('checkout.alert.error.failed_checkout'), 'danger');
        });
    },
    calculateCardsToReturn(room) {
      if (this.checkOutByRoom) {
        return room.dispatched_cards;
      }
      let checkOutCount = 0;
      _.each(this.rooms, (room) => checkOutCount += room.dispatched_cards);
      return checkOutCount;
    },
    setProcessStatus(processStatus) {
      console.log('SetStatus', processStatus);
      this.process = processStatus;
    },
    lostKeyHelper(wsClient, encoderNo, kioskSettings, roomCode) {
      switch (this.keyService) {
      case keySystemType.ASSA_ABLOY_VING_CARD:
        return AssaAbloyVingCardEncoderHelper.lostKeyAssaAbloy(wsClient, kioskSettings, roomCode);
      case keySystemType.ASSA_ABLOY_TESA:
        return AssaAbloyTesaCardEncoderHelper.lostKeyTesa(wsClient, kioskSettings, roomCode);
      case keySystemType.CISA:
        return cisaHelper.lostkeyCisa(wsClient, kioskSettings, roomCode);
      case keySystemType.SALTO:
        return saltoEncoderHelper.lostKeySalto(wsClient, kioskSettings, roomCode);
      case keySystemType.ONITY:
        return onityEncoderHelper.lostKeyOnity(wsClient, kioskSettings, roomCode);
      case keySystemType.DORMAKABA:
        return dormakabaEncoderHelper.lostKeyDormakaba(wsClient, kioskSettings, roomCode);
      }
      return null;
    },
    noMoreCardsToReturn() {
      this.wsClient.close();

      this.checkOutAtPms(this.room);
      /* this.$router.push({
        name: 'checkout-success',
        params: {
          id: this.room.id,
        },
      }); */
    },
  },
};
</script>

<style lang="scss">
  .room {
    width: calc(100% - 4rem);
    text-align: center;
    max-height: calc(100vh - 2rem);
    margin-top: 2rem;
  }

  .room-list {
    margin: auto;
    display: flex;
    justify-content: center;
    max-width: calc(100vw - 4rem);
    flex-wrap: wrap;
  }

  .checkout-description {
    margin-bottom: 2rem;
  }
</style>
